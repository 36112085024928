import { useState, useContext, useEffect } from "react";
import ReusableDialog from "../../components/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  MenuItem,
  Grid2 as Grid,
  Typography,
  Divider,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { Paragliding } from "@mui/icons-material";
import { UIContext, UIState } from "../../providers/UIProvider";
import { LoadingButton } from "@mui/lab";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import {
  createNewStartMemo,
  downloadStartMemoPDF,
  getStartMemo,
  getStartMemoContacts,
  updateStartMemo,
} from "../../apiCalls";
import { useSearchParams } from "react-router-dom";
import { dispatchError } from "../../common/fx";
import useAccessControl from "../../hooks/useAccessControl";
import { forEach, isArray, isObject, transform } from "lodash";

const validationSchema = Yup.object().shape({
  jobType: Yup.string().required("Required"),
  swing: Yup.string().required("Required"),
});

const CreateStartMemo = ({
  disabled = false,
  title,
  disableButtons = false,
  startMemoId,
  buttonLabel,
}: {
  disabled?: boolean;
  disableButtons?: boolean;
  title: string;
  buttonLabel: string;
  startMemoId?: string;
}) => {
  const createStartMemoAccess = useAccessControl("Job", "CreateStartMemo");
  const [error, setError] = useState(false);
  const updateStartMemoAccess = useAccessControl("Job", "UpdateStartMemo");
  const DownloadChangeOrderAccess = useAccessControl("Job", "UpdateStartMemo");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingStartMemo, setLoadingStartMemo] = useState(false);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [open, setOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>(null); // State for dynamic initial values
  const [listOptions, setListOptions] = useState<any>(null); // State for dynamic initial values
  const [downloading, setDownloading] = useState<{
    id: string;
    loading: boolean;
  }>({
    id: "",
    loading: false,
  });
  const [searchParams] = useSearchParams();
  let intentionId = searchParams.get("intentionId");

  const defaultValues = {
    cornerLot: "",
    waterInjection: "",
    startMemoStatus: "Awaiting CM Review",
    piers: "",
    transferSpecial: "",
    salesCounselorName: "",
    moistureConditioning: "",
    moistureConditioningFt: "",
    backsToMainStreet: "",
    constructionManagerName: "Need Data",
    divisionSalesManager: "Need Data",
    divisionConstructionManagerName: "Need Data",
    date: state.changeOrder.updatedAt ?? state.changeOrder.createdAt,
    jobKey: `${state.changeOrder.address.projectNumber}-${state.changeOrder.address.jobNumber}`,
    subdivisionName: state.changeOrder.address.community,
    city: "Prosper",
    buyer:
      state.changeOrder.customerBuyer.contractBuyerNames ??
      `${state.changeOrder.customerBuyer.buyerFirstName} ${state.changeOrder.customerBuyer.buyerLastName}`,
    jobType: "Spec",
    swing: "Left",
    foundationLot: "Design",
    foundationDesign: "",
    additionalNotes: "",
    jobStatus: "New",
    droppedGarage: "No",
    special: "No",
    reasonForSpecial: "",
    inches: "5",
    brick1: "5",
    brick2: "5",
  };

  const flattenObjectWithoutParentKey = (obj: any) =>
    transform(
      obj,
      (result: any, value, key) => {
        if (isObject(value) && !isArray(value)) {
          forEach(value, (nestedValue, nestedKey) => {
            result[nestedKey] = nestedValue;
          });
        } else {
          result[key] = value;
        }
      },
      {}
    );

  const handleFormSubmit = async (values: any) => {
    setLoadingSubmit(true);
    const flattenedValues = flattenObjectWithoutParentKey(values);

    try {
      startMemoId === undefined || !startMemoId
        ? createNewStartMemo(
            {
              intentionId: intentionId as string,
              jobId: state.changeOrder.address.jobID,
              startMemoPayload: flattenedValues,
            },
            (res: any) => {
              const payload = {
                ...state.changeOrder,
                startMemoId: res.data,
              };
              dispatch({
                type: "ChangeOrder",
                payload,
                source: "edit startMemoId state",
              });
            },
            (err: any) => dispatch(dispatchError(err.response.data))
          )
        : updateStartMemo(
            {
              startMemoId: startMemoId as string,
              ...flattenedValues,
            },
            () => {
              setFormValues(values);
            },
            (err: any) => dispatch(dispatchError(err.response.data))
          );

      setLoadingSubmit(false);
      setOpen(false);
    } catch (err) {
      setError(true);
      setLoadingSubmit(false);
    }
  };

  useEffect(() => {
    if (startMemoId !== undefined) {
      setLoadingStartMemo(true);
      getStartMemoContacts(
        state.changeOrder.address.jobID as string,
        (res: any) => {
          if (res) {
            setListOptions(res);
            getStartMemo(
              startMemoId as string,
              (res: any) => {
                if (res) {
                  setFormValues({ ...defaultValues, ...res });
                }
                if (window.location.hash === "#startmemo") {
                  setOpen(true);
                }
                setLoadingStartMemo(false);
              },
              (err: any) => {
                dispatch(dispatchError(err.response.data));
                if (window.location.hash === "#startmemo") {
                  setOpen(true);
                }
              }
            );
          }
        },
        (err: any) => {
          dispatch(dispatchError(err.response.data));
          if (window.location.hash === "#startmemo") {
            setOpen(true);
          }
        }
      );
    } else {
      getStartMemoContacts(
        state.changeOrder.address.jobID as string,
        (res: any) => {
          if (res) {
            setListOptions(res);
          }
          if (window.location.hash === "#startmemo") {
            setOpen(true);
          }
        },
        (err: any) => {
          dispatch(dispatchError(err.response.data));
          if (window.location.hash === "#startmemo") {
            setOpen(true);
          }
        }
      );
      setFormValues(defaultValues);
    }
  }, [startMemoId]);

  const handleDownloadItems = (startMemoId: string) => {
    setDownloading({
      id: startMemoId,
      loading: true,
    });
    DownloadChangeOrderAccess &&
      downloadStartMemoPDF(
        { startMemoId: startMemoId as string },
        async (res: {
          fileData: "string";
          contentType: "string";
          documentName: "string";
        }) => {
          function base64ToBlob(
            base64: string,
            contentType: string = ""
          ): Blob {
            // Convert Base64 to a byte array
            const byteCharacters = atob(base64);
            const byteArrays = [];

            for (
              let offset = 0;
              offset < byteCharacters.length;
              offset += 512
            ) {
              const slice = byteCharacters.slice(offset, offset + 512);
              const byteNumbers = new Array(slice.length);

              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            // Create a blob from the byte array
            return new Blob(byteArrays, { type: contentType });
          }

          function downloadPDF(
            blobName: string,
            contentType: string,
            fileData: string
          ) {
            // Convert Base64 fileData to Blob
            const blob = base64ToBlob(fileData, contentType);

            // Create a Blob URL
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement("a");

            // Set the download attribute with a filename
            link.download = blobName;

            // Set the href to the blob URL
            link.href = blobUrl;

            // Append the link to the document body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Clean-up: remove the link from the document
            document.body.removeChild(link);
          }

          downloadPDF(res.documentName, res.contentType, res.fileData);
          setDownloading({
            id: "",
            loading: false,
          });
        },
        (err: any) => {
          dispatch(dispatchError(err.response.data));
          setDownloading({
            id: "",
            loading: false,
          });
        }
      );
  };

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        disabled={disabled || loadingStartMemo}
        buttonVariant={"contained"}
        buttonText={buttonLabel}
        loading={loadingStartMemo}
        maxWidth="lg"
        title={title}
        content={
          <Formik
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setValues,
            }) => (
              <Form>
                {loadingStartMemo ? (
                  <CircularProgress />
                ) : (
                  <>
                    <DialogContent>
                      <Box p={2}>
                        <Grid
                          container
                          mb={2}
                          size={{ xs: 12, sm: 12 }}
                          sx={{ p: 2, mb: 2, boxShadow: 3, borderRadius: 3 }}
                        >
                          <Grid size={{ xs: 12, sm: 6 }}>
                            <Typography variant="body1">
                              <b>Date:</b>{" "}
                              {moment(values?.date).format("MM-DD-YYYY")}
                            </Typography>
                          </Grid>
                          <Grid size={{ xs: 12, sm: 6 }}>
                            <Typography variant="body1">
                              <b>Status:</b> {values?.startMemoStatus}
                            </Typography>
                          </Grid>
                          <Grid size={{ xs: 12, sm: 6 }}>
                            <Typography variant="body1">
                              <b>Buyer:</b> {values?.buyer}
                            </Typography>
                          </Grid>
                          <Grid size={{ xs: 12, sm: 6 }}>
                            <Typography variant="body1">
                              <b>Subdivision Name:</b> {values?.subdivisionName}
                            </Typography>
                          </Grid>
                          <Grid size={{ xs: 12, sm: 6 }}>
                            <Typography variant="body1">
                              <b>City:</b> {values?.city}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid mt={6} container spacing={2}>
                          <Grid container size={{ xs: 12, sm: 12 }} spacing={2}>
                            <Grid size={{ xs: 12, sm: 6 }}>
                              {
                                <TextField
                                  name="salesCounselorName"
                                  label="Sales Counselor"
                                  select
                                  fullWidth
                                  value={values?.salesCounselorName || ""}
                                  onChange={(e) => {
                                    const foundObject =
                                      listOptions?.startMemoSalesCounselors?.find(
                                        (obj: { salesCounselorName: string }) =>
                                          obj.salesCounselorName ===
                                          e.target.value
                                      ) || {};

                                    // Update all three fields in one go:
                                    setValues((prevValues: any) => ({
                                      ...prevValues,
                                      salesCounselorName:
                                        foundObject.salesCounselorName || "",
                                      salesCounselorEmail:
                                        foundObject.salesCounselorEmail || "",
                                      salesCounselorUserId:
                                        foundObject.salesCounselorUserId || "",
                                    }));
                                  }}
                                  onBlur={handleBlur}
                                >
                                  {listOptions?.startMemoSalesCounselors?.map(
                                    (option: any) => (
                                      <MenuItem
                                        key={option.salesCounselorUserId}
                                        value={option.salesCounselorName}
                                      >
                                        {option.salesCounselorName}
                                      </MenuItem>
                                    )
                                  )}
                                </TextField>
                              }
                            </Grid>

                            <Grid size={{ xs: 12, sm: 6 }}>
                              {
                                <TextField
                                  name="constructionManagerName"
                                  label="Construction Manager"
                                  select
                                  fullWidth
                                  value={values?.constructionManagerName || ""}
                                  onChange={(e) => {
                                    const foundObject =
                                      listOptions?.startMemoConstructionManagers?.find(
                                        (obj: {
                                          constructionManagerName: string;
                                        }) =>
                                          obj.constructionManagerName ===
                                          e.target.value
                                      ) || {};

                                    // Update all three fields in one go:
                                    setValues((prevValues: any) => ({
                                      ...prevValues,
                                      constructionManagerName:
                                        foundObject.constructionManagerName ||
                                        "",
                                      constructionManagerEmail:
                                        foundObject.constructionManagerEmail ||
                                        "",
                                      constructionManagerUserId:
                                        foundObject.constructionManagerUserId ||
                                        "",
                                    }));
                                  }}
                                  onBlur={handleBlur}
                                >
                                  {listOptions?.startMemoConstructionManagers?.map(
                                    (option: any) => (
                                      <MenuItem
                                        key={option.constructionManagerUserId}
                                        value={option.constructionManagerName}
                                      >
                                        {option.constructionManagerName}
                                      </MenuItem>
                                    )
                                  )}
                                </TextField>
                              }
                            </Grid>

                            <Grid size={{ xs: 12, sm: 6 }}>
                              {
                                <TextField
                                  name="divisionSalesManagerName"
                                  label="Division Sales Manager"
                                  select
                                  fullWidth
                                  value={values?.divisionSalesManagerName || ""}
                                  onChange={(e) => {
                                    const foundObject =
                                      listOptions?.startMemoDivisionSalesManagers?.find(
                                        (obj: {
                                          divisionSalesManagerName: string;
                                        }) =>
                                          obj.divisionSalesManagerName ===
                                          e.target.value
                                      ) || {};

                                    // Update all three fields in one go:
                                    setValues((prevValues: any) => ({
                                      ...prevValues,
                                      divisionSalesManagerName:
                                        foundObject.divisionSalesManagerName ||
                                        "",
                                      divisionSalesManagerEmail:
                                        foundObject.divisionSalesManagerEmail ||
                                        "",
                                      divisionSalesManagerUserId:
                                        foundObject.divisionSalesManagerUserId ||
                                        "",
                                    }));
                                  }}
                                  onBlur={handleBlur}
                                >
                                  {listOptions?.startMemoDivisionSalesManagers?.map(
                                    (option: any) => (
                                      <MenuItem
                                        key={option.divisionSalesManagerUserId}
                                        value={option.divisionSalesManagerName}
                                      >
                                        {option.divisionSalesManagerName}
                                      </MenuItem>
                                    )
                                  )}
                                </TextField>
                              }
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6 }}>
                              {
                                <TextField
                                  name="divisionConstructionManagerName"
                                  label="Division Construction Manager"
                                  select
                                  fullWidth
                                  value={
                                    values?.divisionConstructionManagerName ||
                                    ""
                                  }
                                  onChange={(e) => {
                                    const foundObject =
                                      listOptions?.startMemoDivisionConstructionManagers?.find(
                                        (obj: {
                                          divisionConstructionManagerName: string;
                                        }) =>
                                          obj.divisionConstructionManagerName ===
                                          e.target.value
                                      ) || {};

                                    // Update all three fields in one go:
                                    setValues((prevValues: any) => ({
                                      ...prevValues,
                                      divisionConstructionManagerName:
                                        foundObject.divisionConstructionManagerName ||
                                        "",
                                      divisionConstructionManagerEmail:
                                        foundObject.divisionConstructionManagerEmail ||
                                        "",
                                      divisionConstructionManagerUserId:
                                        foundObject.divisionConstructionManagerUserId ||
                                        "",
                                    }));
                                  }}
                                  onBlur={handleBlur}
                                >
                                  {listOptions?.startMemoDivisionConstructionManagers?.map(
                                    (option: any) => (
                                      <MenuItem
                                        key={
                                          option.divisionConstructionManagerUserId
                                        }
                                        value={
                                          option.divisionConstructionManagerName
                                        }
                                      >
                                        {option.divisionConstructionManagerName}
                                      </MenuItem>
                                    )
                                  )}
                                </TextField>
                              }
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mt: 2 }}>
                          <Grid size={{ xs: 12, sm: 4 }}>
                            <TextField
                              name="jobType"
                              label="Job Type"
                              fullWidth
                              select
                              value={values?.jobType}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              error={touched.jobType && Boolean(errors.jobType)}
                              helperText={
                                touched.jobType && (errors.jobType as any)
                              }
                            >
                              <MenuItem value="P&H">P&H</MenuItem>
                              <MenuItem value="Spec">Spec</MenuItem>
                              <MenuItem value="Model">Model</MenuItem>
                              <MenuItem value="Prototype">Prototype</MenuItem>
                              <MenuItem value="Sold">Sold</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid size={{ xs: 12, sm: 4 }}>
                            <TextField
                              name="jobStatus"
                              label="Job Status"
                              select
                              fullWidth
                              value={values?.jobStatus}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {values?.jobType === "P&H" && [
                                <MenuItem key="p&h" value="P&H">
                                  P&H
                                </MenuItem>,
                                <MenuItem key="put_on_p&h" value="Put on P&H">
                                  Put on P&H
                                </MenuItem>,
                                <MenuItem key="new" value="New">
                                  New
                                </MenuItem>,
                                <MenuItem key="revised" value="Revised">
                                  Revised
                                </MenuItem>,
                                <MenuItem key="released" value="Released">
                                  Released
                                </MenuItem>,
                              ]}

                              {values?.jobType === "Spec" && [
                                <MenuItem key="new" value="New">
                                  New
                                </MenuItem>,
                                <MenuItem key="revised" value="Revised">
                                  Revised
                                </MenuItem>,
                              ]}

                              {values?.jobType === "Model" && [
                                <MenuItem key="p&h" value="P&H">
                                  P&H
                                </MenuItem>,
                                <MenuItem key="released" value="Released">
                                  Released
                                </MenuItem>,
                                <MenuItem key="new" value="New">
                                  New
                                </MenuItem>,
                                <MenuItem key="revised" value="Revised">
                                  Revised
                                </MenuItem>,
                              ]}

                              {values?.jobType === "Prototype" && [
                                <MenuItem key="p&h" value="P&H">
                                  P&H
                                </MenuItem>,
                                <MenuItem key="released" value="Released">
                                  Released
                                </MenuItem>,
                                <MenuItem key="new" value="New">
                                  New
                                </MenuItem>,
                                <MenuItem key="revised" value="Revised">
                                  Revised
                                </MenuItem>,
                              ]}

                              {values?.jobType === "Sold" && [
                                <MenuItem key="new" value="New">
                                  New
                                </MenuItem>,
                                <MenuItem key="revised" value="Revised">
                                  Revised
                                </MenuItem>,
                                <MenuItem key="released" value="Released">
                                  Released
                                </MenuItem>,
                              ]}
                            </TextField>
                          </Grid>
                          <Grid size={{ xs: 12, sm: 4 }}>
                            <TextField
                              name="foundationLot"
                              label="Foundation/Lot"
                              fullWidth
                              select
                              value={values?.foundationLot}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <MenuItem value="Design">Design</MenuItem>
                              <MenuItem value="Lot1">Lot 1</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid size={{ xs: 12, sm: 4 }}>
                            <TextField
                              name="swing"
                              label="Swing"
                              fullWidth
                              select
                              value={values?.swing}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.swing && Boolean(errors.swing)}
                              helperText={
                                touched.swing && (errors.swing as any)
                              }
                            >
                              <MenuItem value="Left">Left</MenuItem>
                              <MenuItem value="Right">Right</MenuItem>
                            </TextField>
                          </Grid>

                          <Grid size={{ xs: 12, sm: 4 }}>
                            <TextField
                              name="droppedGarage"
                              label="Dropped Garage"
                              select
                              fullWidth
                              value={values?.droppedGarage}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <MenuItem value="No">No</MenuItem>
                              <MenuItem value="Yes">Yes</MenuItem>
                            </TextField>
                          </Grid>
                          {values?.droppedGarage === "Yes" && (
                            <Grid size={{ xs: 12, sm: 4 }}>
                              <TextField
                                name="inches"
                                label="Inches"
                                fullWidth
                                value={values?.inches}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                          )}
                          <Grid size={{ xs: 12 }}>
                            <TextField
                              name="foundationDesign"
                              label="Foundation/Design"
                              fullWidth
                              multiline
                              value={values?.foundationDesign}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid size={{ xs: 12 }}>
                            <TextField
                              name="additionalNotes"
                              label="Additional Notes"
                              fullWidth
                              multiline
                              value={values?.additionalNotes}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mt: 2 }}>
                          <Grid size={{ xs: 12, sm: 4 }}>
                            <TextField
                              name="special"
                              label="Special"
                              select
                              fullWidth
                              value={values?.special}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <MenuItem value="No">No</MenuItem>
                              <MenuItem value="Yes">Yes</MenuItem>
                            </TextField>
                          </Grid>
                          {values?.special === "Yes" && (
                            <Grid size={{ xs: 12, sm: 4 }}>
                              <TextField
                                name="transferSpecial"
                                label="Transfer Special?"
                                select
                                fullWidth
                                value={values?.transferSpecial}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <MenuItem value="No">No</MenuItem>
                                <MenuItem value="Yes">Yes</MenuItem>
                              </TextField>
                            </Grid>
                          )}
                          {values?.special === "Yes" && (
                            <Grid size={{ xs: 12 }}>
                              <TextField
                                name="reasonForSpecial"
                                label="Reason For Special"
                                fullWidth
                                multiline
                                value={values?.reasonForSpecial}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                          )}
                          <Grid size={{ xs: 12, sm: 4 }}>
                            <TextField
                              name="cornerLot"
                              label="Corner Lot"
                              select
                              fullWidth
                              value={values?.cornerLot}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <MenuItem value="No">No</MenuItem>
                              <MenuItem value="Yes">Yes</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid size={{ xs: 12, sm: 4 }}>
                            <TextField
                              name="waterInjection"
                              label="Water Injection"
                              select
                              fullWidth
                              value={values?.waterInjection}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <MenuItem value="No">No</MenuItem>
                              <MenuItem value="Yes">Yes</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid size={{ xs: 12, sm: 4 }}>
                            <TextField
                              name="piers"
                              label="Piers"
                              select
                              fullWidth
                              value={values?.piers}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <MenuItem value="No">No</MenuItem>
                              <MenuItem value="Yes">Yes</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid size={{ xs: 12, sm: 4 }}>
                            <TextField
                              name="moistureConditioning"
                              label="Moisture Conditioning"
                              select
                              fullWidth
                              value={values?.moistureConditioning}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <MenuItem value="No">No</MenuItem>
                              <MenuItem value="Yes">Yes</MenuItem>
                            </TextField>
                          </Grid>
                          {values?.moistureConditioning === "Yes" && (
                            <Grid size={{ xs: 12, sm: 4 }}>
                              <TextField
                                name="moistureConditioningFt"
                                label="Moisture Conditioning Ft"
                                fullWidth
                                value={values?.moistureConditioningFt}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                          )}
                          <Grid size={{ xs: 12, sm: 4 }}>
                            <TextField
                              name="backsToMainStreet"
                              label="Backs To Main Street"
                              select
                              fullWidth
                              value={values?.backsToMainStreet}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <MenuItem value="No">No</MenuItem>
                              <MenuItem value="Yes">Yes</MenuItem>
                            </TextField>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mt: 2 }}>
                          <Grid size={{ xs: 12, sm: 4 }}>
                            <TextField
                              name="brick1"
                              label="Brick #1"
                              fullWidth
                              value={values?.brick1}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid size={{ xs: 12, sm: 4 }}>
                            <TextField
                              name="brick2"
                              label="Brick #2"
                              fullWidth
                              value={values?.brick2}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Stack
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        direction="row"
                        spacing={1}
                      >
                        <Button
                          onClick={() => {
                            setOpen(false);
                          }}
                          fullWidth
                          sx={{
                            maxWidth: "10rem",
                          }}
                          variant="outlined"
                          color="primary"
                        >
                          Close
                        </Button>
                        {!startMemoId && createStartMemoAccess && (
                          <LoadingButton
                            fullWidth
                            type="submit"
                            loading={loadingSubmit}
                            disabled={loadingSubmit || error || disableButtons}
                            sx={{
                              maxWidth: "10rem",
                            }}
                            variant="contained"
                            color="primary"
                          >
                            Create
                          </LoadingButton>
                        )}

                        {startMemoId !== undefined && updateStartMemoAccess && (
                          <Stack
                            direction={"row"}
                            gap={2}
                            justifyContent={"space-between"}
                          >
                            <LoadingButton
                              fullWidth
                              loading={downloading.loading}
                              variant="outlined"
                              sx={{
                                maxWidth: "10rem",
                              }}
                              onClick={() =>
                                handleDownloadItems(startMemoId as string)
                              }
                            >
                              Print
                            </LoadingButton>

                            <Stack
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              direction="row"
                              spacing={1}
                            >
                              {" "}
                              <LoadingButton
                                type="submit"
                                fullWidth
                                loading={loadingSubmit}
                                disabled={
                                  loadingSubmit || error || disableButtons
                                }
                                sx={{
                                  maxWidth: "10rem",
                                }}
                                variant="contained"
                                color="primary"
                              >
                                Update
                              </LoadingButton>
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    </DialogActions>
                  </>
                )}
              </Form>
            )}
          </Formik>
        }
      />
    </>
  );
};

export default CreateStartMemo;
