//import * as React from "react";
import React, { useContext, useState, useEffect, useCallback } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link as RLink } from "react-router-dom";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  DataGridPro,
  GridColDef,
  GridFilterModel,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import {
  Stack,
  Tooltip,
  Button,
  LinearProgress,
  TextField,
  TablePagination,
  InputAdornment,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Switch,
  useMediaQuery,
  ListItemText,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { numberWithCommas } from "../../utils/formatMoney";
import {
  allChangeOrderOptions,
  getIntentionOrderApi,
  JobOptionsSummary,
  JobOptionsSummaryExcel,
} from "../../apiCalls";
import { UIContext } from "../../providers/UIProvider";
import { capitalize, debounce, isEmpty, uniq } from "lodash";
import {
  Download,
  RemoveCircleOutline,
  SearchOutlined,
} from "@mui/icons-material";
import { dispatchError } from "../../common/fx";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import useAccessControl from "../../hooks/useAccessControl";
import WarningModal from "../../modals/Warning";
import SummaryCreateChangeOrder from "../../modals/SummaryCreateChangeOrder/SummaryCreateChangeOrder";
import "./Summary.scss";

function downloadCSV(data: any, filename = "download.csv") {
  const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function downloadExcel(data: any, filename = "download.xlsx") {
  const blob = new Blob([data]);
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: (theme) => theme.spacing(12), mb: (theme) => theme.spacing(4) }}
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://highlandhomes.com/">
        Highland Homes
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

//const ITEM_HEIGHT = 48;
//const ITEM_PADDING_TOP = 8;
/*const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};*/

export default function Index() {
  const navigate = useNavigate();
  const [state, dispatch] = useContext<any>(UIContext);
  const isSmallScreen = useMediaQuery("(max-width:1300px)"); // Media query for screen width <= 1080px

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  let addressId = searchParams.get("addressId");
  let jobID = searchParams.get("jobID");
  let projectNumber = searchParams.get("projectNumber");
  let jobNumber = searchParams.get("jobNumber");
  const AllChangeOrderOptionsAccess = useAccessControl("Job", "GetAllOptions");
  const GetIntentionAccess = useAccessControl("Intention", "GetIntention");
  const ExportSubmittedOptionsToExcelAccess = useAccessControl(
    "Job",
    "ExportSubmittedOptionsToExcel"
  );
  const ModelFromExistingIntention = useAccessControl(
    "Intention",
    "ModelFromExistingIntention"
  );
  const [downloading, setDownloading] = useState<boolean>(false);
  const [downloadingGray, setDownloadingGray] = useState<boolean>(false);
  const [downloadingExcel, setDownloadingExcel] = useState<boolean>(false);

  const [searchOptions, setSearchOptions] = useState<{
    HighlandCategoryType: any;
    changeOrderNumbers: any;
    Category: any;
    priceToggle: boolean;
    Status: any;
    search: any;
    discardCancelling: boolean;
  }>({
    priceToggle: false,
    changeOrderNumbers: [],
    HighlandCategoryType: {
      highlandCategory: "",
    },
    search: "",
    Category: {
      category: "",
    },
    Status: "",
    discardCancelling: false,
  });
  const [displayPlanWarning, setDisplayPlanWarning] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [cancellingRows, setCancellingRows] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filt, setFilt] = useState({
    items: [],
    quickFilterValues: [""],
  });

  const [category, setCategories] = useState({
    highlandCategories: [],
    changeOrderNumbers: [],
    categories: [""],
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });
  const [paginationLoad, setPaginationLoad] = useState(false);

  // Update the pagination model when the TablePagination changes
  const handleChangePage = (event: unknown, newPage: number) => {
    setPaginationLoad(true);
    setPaginationModel((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: parseInt(event.target.value, 10),
      page: 0,
    }));
  };

  useEffect(() => {
    const params = {
      JobId: jobID,
      search: searchOptions?.search,
      highlandCategoryType:
        searchOptions?.HighlandCategoryType.highlandCategory,
      category: searchOptions?.Category.category,
      changeOrderNumbers: searchOptions?.changeOrderNumbers.map(
        (obj: { changeOrderNumber: string }) => obj.changeOrderNumber
      ),
      statuses: searchOptions?.Status ? [searchOptions?.Status] : [],
      pageSize: paginationModel.pageSize,
      pageNumber: paginationModel?.page + 1, // API might expect 1-indexed pages
      includeCancelationOption: searchOptions.discardCancelling,
    };

    AllChangeOrderOptionsAccess &&
      allChangeOrderOptions(
        params,
        (res: any) => {
          dispatch({
            type: "Navbar",
            payload: {
              title: `Options Summary - ${res.data.jobAddress}`,
            },
          });
          dispatch({
            type: "ChangeOrderSummaryItems",
            payload: res.data.value,
          });

          if (
            isEmpty(category.changeOrderNumbers) ||
            searchOptions.discardCancelling
          ) {
            setCategories({
              changeOrderNumbers: res.data.changeOrderNumbers,
              categories: res.data.categories,
              highlandCategories: res.data.highlandCategories,
            });
          }
          setTotalRows(res.data.rowCount); // Set the total number of rows from the response
          setPaginationLoad(false);
        },
        (err: any) => {
          dispatch({
            type: "ChangeOrderSummaryItems",
            payload: [],
          });
          dispatch(dispatchError(err.response.data));
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchOptions,
    jobID,
    paginationModel.page,
    paginationModel.pageSize,
    paginationModel,
    dispatch,
    AllChangeOrderOptionsAccess,
  ]);

  useEffect(() => {
    const params = {
      JobId: jobID,
      search: "base",
      highlandCategoryType:
        searchOptions?.HighlandCategoryType.highlandCategory,
      category: searchOptions?.Category.category,
      changeOrderNumbers: searchOptions?.Category.changeOrderNumbers,
      statuses: searchOptions?.Status ? [searchOptions?.Status] : [],
      pageSize: paginationModel.pageSize,
      pageNumber: paginationModel?.page + 1, // API might expect 1-indexed pages
    };

    AllChangeOrderOptionsAccess &&
      allChangeOrderOptions(
        params,
        (res: any) => {
          if (
            res.data.value.filter(
              (obj: { highlandCategoryType: string }) =>
                obj.highlandCategoryType === "BasePlan"
            ).length >= 2
          ) {
            setDisplayPlanWarning(true);
          }
        },
        (err: any) => {
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          );
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllChangeOrderOptionsAccess]);

  useEffect(() => {
    GetIntentionAccess && fetchIntention();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetIntentionAccess]);

  useEffect(() => {
    const newCancellingRows = rowSelectionModel
      .map((coItemId: any) =>
        state.COSummaryItems.find((coItem: any) => coItem.id === coItemId)
      )
      .filter(
        (coItem: any) =>
          coItem.category !== "Plan and Elevation" &&
          coItem.category !== "Formatting Separator" &&
          coItem.status === "Submitted" &&
          (!coItem.cancelsOptionId || coItem.cancelsOptionId === null)
      );
    setCancellingRows(newCancellingRows);
  }, [rowSelectionModel]);

  const fetchIntention = (success?: any) => {
    //setLoading(true);
    getIntentionOrderApi(
      addressId as string,
      (res: any) => {
        dispatch({
          type: "SelectedJob",
          payload: {
            ...res.data,
            buyerLegalName: {
              contractBuyerNames: res.data.buyerLegalName,
              connectionNumber: res.data.buyerSalesforceConnectionNumber,
              salesforceAccountId: res.data.buyerSalesforceAccountId,
            },
          },
        });
        dispatch({
          type: "ChangeOrder",
          payload: {
            address: res.data.address,
            addressFreeform: "",
            community: {
              name: res.data.community,
              projectNumber: res.data.projectNumber,
            },
            customerBuyer: {
              buyerFirstName: res.data.buyerFirstName,
              buyerLastName: res.data.buyerLastName,
              buyerLegalName: res.data.buyerLegalName,
              contractBuyerNames: res.data.buyerLegalName,
              connectionId: res.data.buyerSalesforceAccountId,
              connectionNumber: res.data.buyerSalesforceConnectionNumber,
            },
            plan: {
              planNumber: res.data.planNumber,
              planId: res.data.planId,
              planElevationNumber: res.data.planElevationNumber,
            },
            elevation: {
              planElevationNumber: res.data.planElevationNumber,
              planElevationId: res.data.planElevationId,
            },
            keyword: "",
          },
          source: "Selected page Fetch COs",
        });
        dispatch({
          type: "Navbar",
          payload: {
            title: `Option Summary - ${
              res.data.addressType === "LotAndBlock"
                ? capitalize(res.data.lot + res.data.block)
                : res.data.address
            } (${res.data.planName} / ${res.data.planElevationNumber})`,
          },
        });
        {
          console.log(res.data);
        }
        /*JobHasSubmittedBasePlan(
          { jobId: res.data.jobId },
          (res: any) => setHasSubmittedBasePlan(res.data),
          (err: any) => dispatch(dispatchError(err.response.data))
        );
        JobHasSubmittedIntention(
          { jobId: res.data.jobId },
          (res: any) => setHasSubmittedIntention(res.data),
          (err: any) => dispatch(dispatchError(err.response.data))
        );
        success && success(res);
        setLoading(false);*/
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  const columns: GridColDef[] = [
    {
      disableColumnMenu: true,
      editable: false,
      headerName: "",
      field: "rowFlag",
      flex: 1,
      maxWidth: 50,
      renderCell: (params: any) => {
        if (!!params.row.cancelsOptionId)
          return (
            <span
              style={{
                color: "#e56d29",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              <RemoveCircleOutline />
            </span>
          );
        return null;
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      width: 150,
      renderCell: (params) => (
        <Tooltip
          placement="right-start"
          title={`Last Updated On  ${moment(params.row.updatedAt).format(
            "MM-DD-YYYY"
          )} by  ${params.row.updatedBy} `}
        >
          <span className="table-cell-trucate">
            {" "}
            {moment(params.row.updatedAt).format("MM-DD-YYYY")}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
    },
    {
      field: "changeOrderNumber",
      headerName: "CO Number",
      width: 175,
      renderCell: (params) =>
        params?.row.status === "Submitted" ? (
          <RLink
            to={{
              pathname: "/view",
              search: `?intentionId=${params.row.intentionId}`,
            }}
          >
            {params.row.changeOrderNumber}
          </RLink>
        ) : (
          <RLink
            to={{
              pathname: "/edit",
              search: `?intentionId=${params.row.intentionId}`,
            }}
          >
            {params.row.changeOrderNumber}
          </RLink>
        ),
    },
    {
      field: "planElevation",
      headerName: "Plan / Elev",
      width: 110,
    },
    {
      field: "highlandCategoryType",
      headerName: "Option Category",
      width: 175,
    },
    {
      field: "category",
      headerName: "Option",
      width: 150,

      renderCell: (params) => (
        <Box
          sx={{
            textTransform: "capitalize",
          }}
        >
          {params.value}
        </Box>
      ),
    },

    {
      field: "optionCode",
      headerName: "Option Code",
      width: 150,
    },
    {
      field: "description",
      headerName: "Description",
      width: 400,
      renderCell: (params) => (
        <Box
          sx={{
            textTransform: "capitalize",
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 200,
      renderCell: (params) => (
        <Tooltip title={`${params.row.notes}`}>{params.row.notes}</Tooltip>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 125,

      valueFormatter: (params) => `${numberWithCommas(params.value)}`,
    },
    {
      field: "unitPrice",
      headerAlign: "right",
      align: "right",
      headerName: "Unit Price",
      width: 150,

      valueFormatter: (params) => `$${numberWithCommas(params.value)}`,
    },
    {
      field: "salesPrice",
      headerName: "Total Price",
      headerAlign: "right",
      align: "right",
      width: 150,

      valueFormatter: (params) => `$${numberWithCommas(params.value)}`,
    },
  ];

  const getRowId = (row: {
    id: string;
    optionCode: string;
    description: string;
  }) => row.id;

  const handleSearchChange = useCallback(
    debounce((value) => {
      setSearchOptions((prevOptions) => ({
        ...prevOptions,
        search: value,
      }));
      setPaginationModel((prev) => ({
        ...prev,
        page: 0,
      }));
    }, 1000), // 1000ms delay
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth={false}>
        <CssBaseline />

        <Box
          width={"100%"}
          sx={{
            mt: (theme) => theme.spacing(4),
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Stack
              width={"70%"}
              direction="row"
              alignItems="flex-start"
              spacing={2}
            >
              <TextField
                fullWidth
                sx={{
                  minWidth: "6rem",
                }}
                size="small"
                label="Search"
                variant="outlined"
                onChange={(e: any) => handleSearchChange(e.target.value)}
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined color="info" />
                    </InputAdornment>
                  ),
                }}
              />
              <Autocomplete
                defaultValue={""}
                fullWidth
                freeSolo={false}
                getOptionLabel={(option) => {
                  return option;
                }}
                multiple={false}
                onChange={(e, value, reason) => {
                  if (reason === "clear") {
                    setSearchOptions({
                      ...searchOptions,
                      Status: "",
                    });
                    setPaginationModel((prev) => ({
                      ...prev,
                      page: 0,
                    }));
                  } else
                    setSearchOptions({
                      ...searchOptions,
                      Status: value,
                    });
                  setPaginationModel((prev) => ({
                    ...prev,
                    page: 0,
                  }));
                }}
                options={["Submitted", "Pending", "Canceled"]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Status"
                    fullWidth
                  />
                )}
                size="small"
                value={searchOptions?.Status || null}
              />

              <Autocomplete
                fullWidth
                freeSolo={false}
                getOptionLabel={(option) => {
                  return option.changeOrderNumber;
                }}
                multiple={true}
                onChange={(e, value, reason) => {
                  console.log(value);
                  if (reason === "clear") {
                    setPaginationModel((prev) => ({
                      ...prev,
                      page: 0,
                    }));
                    setSearchOptions({
                      ...searchOptions,
                      changeOrderNumbers: [],
                    });
                  } else
                    setPaginationModel((prev) => ({
                      ...prev,
                      page: 0,
                    }));
                  setSearchOptions({
                    ...searchOptions,
                    changeOrderNumbers: value,
                  });
                }}
                options={category.changeOrderNumbers}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="CO Number"
                    fullWidth
                  />
                )}
                size="small"
                value={searchOptions?.changeOrderNumbers}
              />

              <Autocomplete
                defaultValue={""}
                fullWidth
                getOptionLabel={(option) => {
                  return option.highlandCategory;
                }}
                filterOptions={(options: any[], state: any) => {
                  let newOptions: any = [];
                  // Escape special regex characters in the input value and convert to lower case
                  const inputValue = state.inputValue
                    .toLowerCase()
                    .replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
                  const regex = new RegExp(`\\b${inputValue}`, "i"); // 'i' for case insensitive

                  options.forEach((element) => {
                    let name = element?.highlandCategory?.toLowerCase();
                    // Check if any word starts with the input value
                    if (regex.test(name)) {
                      newOptions.push(element);
                    }
                  });
                  return newOptions;
                }}
                renderOption={(props, item) => (
                  <li {...props} key={item.highlandCategory}>
                    <ListItemText>{item.highlandCategory}</ListItemText>
                  </li>
                )}
                multiple={false}
                onChange={(e, value, reason) => {
                  if (reason === "clear") {
                    setPaginationModel((prev) => ({
                      ...prev,
                      page: 0,
                    }));
                    setSearchOptions({
                      ...searchOptions,
                      HighlandCategoryType: { highlandCategory: "" },
                    });
                  } else
                    setPaginationModel((prev) => ({
                      ...prev,
                      page: 0,
                    }));
                  setSearchOptions({
                    ...searchOptions,
                    HighlandCategoryType: {
                      highlandCategory: value.highlandCategory,
                    },
                  });
                }}
                options={category.highlandCategories}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Option Category"
                    fullWidth
                  />
                )}
                size="small"
                value={searchOptions?.HighlandCategoryType || null}
              />
              <Autocomplete
                defaultValue={""}
                fullWidth
                freeSolo={false}
                getOptionLabel={(option) => {
                  return option.category;
                }}
                renderOption={(props, item) => (
                  <li {...props} key={item.category}>
                    <ListItemText>{item.category}</ListItemText>
                  </li>
                )}
                multiple={false}
                onChange={(e, value, reason) => {
                  if (reason === "clear") {
                    setPaginationModel((prev) => ({
                      ...prev,
                      page: 0,
                    }));
                    setSearchOptions({
                      ...searchOptions,
                      Category: { category: "" },
                    });
                  } else
                    setPaginationModel((prev) => ({
                      ...prev,
                      page: 0,
                    }));
                  setSearchOptions({
                    ...searchOptions,
                    Category: { category: value.category },
                  });
                }}
                options={category.categories}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Category"
                    fullWidth
                  />
                )}
                size="small"
                value={searchOptions?.Category || null}
              />
              <Stack direction={isSmallScreen ? "column" : "row"} spacing={2}>
                <FormGroup sx={{ width: "100%" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={searchOptions?.priceToggle}
                        onChange={(e, checked) => (
                          setPaginationModel((prev) => ({
                            ...prev,
                            page: 0,
                          })),
                          setSearchOptions({
                            ...searchOptions,
                            priceToggle: checked,
                          })
                        )}
                        inputProps={{ "aria-label": "Show Prices" }}
                      />
                    }
                    label="Show Prices"
                    sx={{ textWrap: "nowrap" }}
                  />
                </FormGroup>
                <FormGroup sx={{ flexGrow: "2", width: "100%" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={searchOptions?.discardCancelling}
                        onChange={(e, checked) => (
                          setPaginationModel((prev) => ({
                            ...prev,
                            page: 0,
                          })),
                          setSearchOptions({
                            ...searchOptions,
                            discardCancelling: checked,
                          })
                        )}
                        inputProps={{
                          "aria-label": "Show Deleted and Deleting options",
                        }}
                      />
                    }
                    label="Show Deletes"
                    sx={{ textWrap: "nowrap" }}
                  />
                </FormGroup>
              </Stack>
            </Stack>

            <Stack
              flexDirection={"row"}
              justifyContent={"center"}
              gap={2}
              alignContent={"center"}
              className="summary-tools"
            >
              {!state.selectedJob.archived &&
                ModelFromExistingIntention &&
                addressId &&
                cancellingRows.length > 0 && (
                  <div style={{ width: "100%" }}>
                    <SummaryCreateChangeOrder
                      buttonTitle="Delete with CO"
                      createButtonTitle="Create"
                      intentionId={addressId}
                      modalTitle="Delete Submitted Options"
                      intentions={cancellingRows}
                    />
                  </div>
                )}
              {ExportSubmittedOptionsToExcelAccess && (
                <LoadingButton
                  size="medium"
                  fullWidth
                  loading={downloadingExcel}
                  disabled={rowSelectionModel.length <= 0}
                  variant="contained"
                  onClick={() => {
                    setDownloadingExcel(true);
                    JobOptionsSummaryExcel(
                      {
                        optionIds: rowSelectionModel as string[],
                        showPrices: searchOptions.priceToggle,
                      },
                      async (res: { data: ArrayBuffer }) => {
                        downloadExcel(
                          res.data,
                          `${projectNumber}-${jobNumber}-SubmittedOptionsExport.xlsx`
                        );
                        setDownloadingExcel(false);
                      },
                      (err: any) => {
                        dispatch(
                          dispatchError({
                            message: err.message,
                            statusText: err.response.statusText,
                            title: err.response.data.title,
                            status: err.response.status,
                            detail: err.response.data.detail,
                            data: err.response.data,
                          })
                        );
                        setDownloadingExcel(false);
                      }
                    );
                  }}
                >
                  <Download /> EXPORT
                </LoadingButton>
              )}
              <Stack direction={isSmallScreen ? "column" : "row"} spacing={2}>
                <LoadingButton
                  size="medium"
                  fullWidth
                  sx={{
                    minWidth: "11rem",
                  }}
                  loading={downloadingGray}
                  disabled={rowSelectionModel.length <= 0}
                  variant="contained"
                  onClick={() => {
                    setDownloadingGray(true);
                    JobOptionsSummary(
                      {
                        optionIds: rowSelectionModel as string[],
                        showPrices: searchOptions.priceToggle,
                        useTemplateForPrinting: true,
                      },
                      async (res: {
                        data: {
                          fileData: "string";
                          contentType: "string";
                          documentName: "string";
                        };
                      }) => {
                        function base64ToBlob(
                          base64: string,
                          contentType: string = ""
                        ): Blob {
                          // Convert Base64 to a byte array
                          const byteCharacters = atob(base64);
                          const byteArrays = [];

                          for (
                            let offset = 0;
                            offset < byteCharacters.length;
                            offset += 512
                          ) {
                            const slice = byteCharacters.slice(
                              offset,
                              offset + 512
                            );
                            const byteNumbers = new Array(slice.length);

                            for (let i = 0; i < slice.length; i++) {
                              byteNumbers[i] = slice.charCodeAt(i);
                            }

                            const byteArray = new Uint8Array(byteNumbers);
                            byteArrays.push(byteArray);
                          }

                          // Create a blob from the byte array
                          return new Blob(byteArrays, { type: contentType });
                        }

                        function downloadPDF(
                          documentName: string,
                          contentType: string,
                          fileData: string
                        ) {
                          // Convert Base64 fileData to Blob
                          const blob = base64ToBlob(fileData, contentType);

                          // Create a Blob URL
                          const blobUrl = window.URL.createObjectURL(blob);

                          // Create a link element
                          const link = document.createElement("a");

                          // Set the download attribute with a filename
                          link.download = documentName;

                          // Set the href to the blob URL
                          link.href = blobUrl;

                          // Append the link to the document body
                          document.body.appendChild(link);

                          // Programmatically click the link to trigger the download
                          link.click();

                          // Clean-up: remove the link from the document
                          document.body.removeChild(link);
                        }
                        downloadPDF(
                          res.data.documentName,
                          res.data.contentType,
                          res.data.fileData
                        );
                        setDownloadingGray(false);
                      },
                      (err: any) => {
                        dispatch(
                          dispatchError({
                            message: err.message,
                            statusText: err.response.statusText,
                            title: err.response.data.title,
                            status: err.response.status,
                            detail: err.response.data.detail,
                            data: err.response.data,
                          })
                        );
                        setDownloadingGray(false);
                      }
                    );
                  }}
                >
                  Print Grayscale
                </LoadingButton>
                <LoadingButton
                  size="medium"
                  sx={{
                    minWidth: "9rem",
                  }}
                  fullWidth
                  loading={downloading}
                  disabled={rowSelectionModel.length <= 0}
                  variant="contained"
                  onClick={() => {
                    setDownloading(true);
                    JobOptionsSummary(
                      {
                        optionIds: rowSelectionModel as string[],
                        showPrices: searchOptions.priceToggle,
                        useTemplateForPrinting: false,
                      },
                      async (res: {
                        data: {
                          fileData: "string";
                          contentType: "string";
                          documentName: "string";
                        };
                      }) => {
                        function base64ToBlob(
                          base64: string,
                          contentType: string = ""
                        ): Blob {
                          // Convert Base64 to a byte array
                          const byteCharacters = atob(base64);
                          const byteArrays = [];

                          for (
                            let offset = 0;
                            offset < byteCharacters.length;
                            offset += 512
                          ) {
                            const slice = byteCharacters.slice(
                              offset,
                              offset + 512
                            );
                            const byteNumbers = new Array(slice.length);

                            for (let i = 0; i < slice.length; i++) {
                              byteNumbers[i] = slice.charCodeAt(i);
                            }

                            const byteArray = new Uint8Array(byteNumbers);
                            byteArrays.push(byteArray);
                          }

                          // Create a blob from the byte array
                          return new Blob(byteArrays, { type: contentType });
                        }

                        function downloadPDF(
                          documentName: string,
                          contentType: string,
                          fileData: string
                        ) {
                          // Convert Base64 fileData to Blob
                          const blob = base64ToBlob(fileData, contentType);

                          // Create a Blob URL
                          const blobUrl = window.URL.createObjectURL(blob);

                          // Create a link element
                          const link = document.createElement("a");

                          // Set the download attribute with a filename
                          link.download = documentName;

                          // Set the href to the blob URL
                          link.href = blobUrl;

                          // Append the link to the document body
                          document.body.appendChild(link);

                          // Programmatically click the link to trigger the download
                          link.click();

                          // Clean-up: remove the link from the document
                          document.body.removeChild(link);
                        }
                        downloadPDF(
                          res.data.documentName,
                          res.data.contentType,
                          res.data.fileData
                        );
                        setDownloading(false);
                      },
                      (err: any) => {
                        dispatch(
                          dispatchError({
                            message: err.message,
                            statusText: err.response.statusText,
                            title: err.response.data.title,
                            status: err.response.status,
                            detail: err.response.data.detail,
                            data: err.response.data,
                          })
                        );
                        setDownloading(false);
                      }
                    );
                  }}
                >
                  Print Color
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>

          {state.COSummaryItems && (
            <Stack flexDirection={"column"} gap={2} mt={2}>
              <DataGridPro
                scrollbarSize={10}
                sx={{
                  marginTop: (theme) => theme.spacing(2),
                  height: "100%",
                  "& .canceled-row": {
                    textDecoration: "line-through",
                  },
                  minHeight: "20rem",
                  "& .MuiDataGrid-virtualScroller": {
                    transform: "rotateX(180deg)",
                  },
                  "& .MuiDataGrid-virtualScrollerContent": {
                    transform: "rotateX(180deg)",
                  },
                }}
                columnVisibilityModel={{
                  unitPrice: searchOptions.priceToggle,
                  salesPrice: searchOptions.priceToggle,
                }}
                paginationMode="server"
                initialState={{
                  sorting: {
                    sortModel: [{ field: "changeOrderNumber", sort: "asc" }],
                  },
                }}
                checkboxSelection
                getRowClassName={(params: any) =>
                  `${params?.row?.status === "Canceled" ? "canceled-row" : ""}`
                }
                getRowId={getRowId}
                rowCount={totalRows}
                hideFooter
                loading={loading}
                //@ts-ignore
                columns={columns}
                rows={state.COSummaryItems}
                slots={{
                  loadingOverlay: LinearProgress,
                }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                onSortModelChange={(newSortModel) => console.log(newSortModel)}
                rowSelectionModel={rowSelectionModel}
                filterModel={filt as GridFilterModel | undefined}
                pagination
                getRowHeight={() => "auto"}
              />
              <TablePagination
                component="div"
                count={totalRows} // Total row count comes from the server
                page={paginationModel.page}
                nextIconButtonProps={{
                  disabled:
                    paginationLoad ||
                    paginationModel.pageSize > state.COSummaryItems.length
                      ? true
                      : false,
                }}
                rowsPerPageOptions={[10, 25, 50, 100, 200]}
                onPageChange={handleChangePage}
                rowsPerPage={paginationModel.pageSize}
                onRowsPerPageChange={handleChangePageSize}
              />
            </Stack>
          )}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            mt={2}
          >
            <Button variant="outlined" onClick={() => navigate(-1)}>
              Back
            </Button>
            {/* <Box>
              <SubmitCancellationChangeOrder />
            </Box> */}
          </Stack>
        </Box>
        <WarningModal
          title={"Multiple Plan and Elevation on Job"}
          message={
            "This job has more than one plan and elevation.  Please carefully choose the option lines when printing this job option summary report."
          }
          setOpen={setDisplayPlanWarning}
          open={displayPlanWarning}
        />
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
